import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';

import {api, imagesUploadUrl, noopUrl} from '~src/api';

import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';

import SunEditor, {buttonList} from 'suneditor-react';

require('suneditor/dist/css/suneditor.min.css');

import {Col, Row, Button, Space, Input, Upload, Tooltip, Divider, message, DatePicker} from 'antd/lib';
import {UploadChangeParam} from 'antd/lib/upload/interface';
import moment, {Moment} from 'moment';

import {UploadOutlined, CopyOutlined} from '@ant-design/icons/lib';

import {LocaleContext} from '~src/context';
import {navigate} from 'gatsby';

import {Routes} from '~src/utils/routes';

interface IContentProps {}

interface IRcFile extends File {
  uid: string;
}

const Content: React.FC<IContentProps> = () => {
  const {dispatch, articles} = useStoreon<IState, IEvents>('articles');

  const [articleID, setArticleID] = useState(0);
  const [creationTime, setCreationTime] = useState<Moment | null | undefined>(moment());

  const [textHeader, setTextHeader] = useState('');
  const [previewDescription, setPreviewDescription] = useState<string>('');
  const [textDescription, setTextDescription] = useState('');
  const [textArticle, setTextArticle] = useState('');
  const [imageReadyForCopy, setImageReadyForCopy] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [imageURL, setImageURL] = useState<string>('http://');
  const [imageName, setImageName] = useState<string>('');

  const {
    pages: {
      admin: {
        article: {exitButton, saveButton},
      },
    },
  } = useContext(LocaleContext);

  useEffect(() => {
    dispatch('articles/fetch-articles', {strategy: 'overwrite'});
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const urlParamsId = urlParams.get('id');
      if (urlParamsId != null) {
        setArticleID(+urlParamsId);
        const article = articles.values.find(article => article.id === articleID);
        if (article) {
          article.creationTime && setCreationTime(moment(article.creationTime, 'DD.MM.YYYY'));
          article.title && setTextHeader(article.title);
          article.previewUrl && setPreviewDescription(article.previewUrl);
          article.description && setTextDescription(article.description);
          article.text && setTextArticle(article.text);
        }
      }
    }
  }, [articles]);

  const handleClickAtExitButton = () => {
    dispatch('articles/fetch-articles', {strategy: 'overwrite'});
    navigate(Routes.articlesModeration);
  };
  const handleClickAtSaveButton = () => {
    if (articleID === 0) {
      dispatch('articles/send-article', {
        creation_time: moment(creationTime).format('DD.MM.YYYY'),
        title: textHeader,
        preview_url: previewDescription,
        description: textDescription,
        text: textArticle,
      });
      dispatch('articles/fetch-articles', {strategy: 'overwrite'});
      navigate(Routes.articlesModeration);
      dispatch('articles/fetch-articles', {strategy: 'overwrite'});
    } else {
      dispatch('articles/patch-article', {
        id: articleID,
        creation_time: moment(creationTime).format('DD.MM.YYYY'),
        title: textHeader,
        preview_url: previewDescription,
        description: textDescription,
        text: textArticle,
      });
      dispatch('articles/fetch-articles', {strategy: 'overwrite'});
      navigate(Routes.articlesModeration);
    }
  };

  const handleChangeCreationTime = (date: Moment | null | undefined) => {
    setCreationTime(date);
  };
  const handleChangeHeader = (e: {target: {value: React.SetStateAction<string>}}) => {
    setTextHeader(e.target.value);
  };
  const handleChangePreviewDescription = (e: {target: {value: React.SetStateAction<string>}}) => {
    setPreviewDescription(e.target.value);
  };
  const handleChangeTextDescription = (e: {target: {value: React.SetStateAction<string>}}) => {
    setTextDescription(e.target.value);
  };
  const handleChangeTextArticle = (value: string) => {
    setTextArticle(value);
  };

  const copyToClipBoard = (imageURL: string) => {
    const area = document.createElement('textarea');
    document.body.appendChild(area);
    area.value = imageURL;
    area.select();
    try {
      document.execCommand('copy');
      message.success('URL адрес изображения скопирован в буфер обмена');
    } catch (err) {
      message.error('copy failed.');
    }
    document.body.removeChild(area);
  };

  const handleChangeUploadImage = (info: UploadChangeParam) => {
    if (info.file.status === 'done') {
      const formData = new FormData();
      formData.append('image', info.file.originFileObj as IRcFile);
      setUploading(true);
      api
        .post('/articles/upload', formData)
        .then(response => {
          setImageReadyForCopy(true);
          setImageName(response.data.name);
          const url = imagesUploadUrl + '/' + response.data.name;
          setImageURL(url);
          copyToClipBoard(url);
          message.success('Файл успешно загружен');
          setUploading(false);
        })
        .catch(() => {
          message.error('upload failed.');
          setUploading(false);
          setImageReadyForCopy(false);
        });
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  return (
    <Row gutter={[0, 20]}>
      <Col span={24}>
        <Divider orientation="left">Загрузка изображений:</Divider>
        <Upload action={noopUrl} onChange={handleChangeUploadImage} showUploadList={false}>
          <Button icon={<UploadOutlined />} style={{height: 'auto'}}>
            <span style={{whiteSpace: 'break-spaces'}}>
              {uploading ? 'Подождите идет загрузка...' : 'Выбрать изображение для загрузки и получить Image URL'}
            </span>
          </Button>
        </Upload>
      </Col>

      {imageReadyForCopy && (
        <>
          <Col sm={24} md={'auto'}>
            <img style={{width: '100px', marginRight: '10px'}} src={imageURL} alt={imageName} />
          </Col>
          <Col sm={24} md={'auto'}>
            <Input.Group compact style={{margin: '10px 0', display: 'flex', alignItems: 'center'}}>
              <Input addonBefore="Image URL" style={{width: 'calc(100% - 200px)'}} value={imageURL} />
              <Tooltip title="copy image url">
                <Button icon={<CopyOutlined />} onClick={() => copyToClipBoard(imageURL)} />
              </Tooltip>
            </Input.Group>
          </Col>
        </>
      )}

      <Divider orientation="left">Дата:</Divider>
      <Col span={24}>
        <DatePicker
          format="DD.MM.YYYY"
          value={creationTime ? moment(creationTime, 'DD.MM.YYYY') : undefined}
          onChange={handleChangeCreationTime}
        />
      </Col>

      <Divider orientation="left">Заголовок:</Divider>
      <Col span={24}>
        <Input
          showCount={true}
          placeholder="Заголовок"
          value={textHeader}
          onChange={handleChangeHeader}
          maxLength={100}
        />
      </Col>
      <Divider orientation="left">Превью и краткое описание:</Divider>
      <Col span={24}>
        <Row gutter={20}>
          <Col span={24}>
            {previewDescription && (
              <img style={{maxWidth: '250px', maxHeight: '150px'}} src={previewDescription} alt={'preview-image'} />
            )}
          </Col>
          <Col span={24}>
            <Input
              addonBefore="Превью Image URL"
              value={previewDescription}
              placeholder="Вставьте Image URL для превью"
              onChange={handleChangePreviewDescription}
            />
          </Col>

          <Col span={24}>
            <Input.TextArea
              showCount={true}
              value={textDescription}
              placeholder="Вставьте краткое описание статьи (не более 300 знаков/символов)"
              style={{height: 102}}
              onChange={handleChangeTextDescription}
              maxLength={300}
            />
          </Col>
        </Row>
      </Col>

      <Divider orientation="left">Текст статьи:</Divider>
      <Col span={24}>
        <SunEditor
          placeholder="Вставьте текст для статьи"
          setContents={textArticle}
          onChange={handleChangeTextArticle}
          setOptions={{
            height: '300',
            buttonList: buttonList.complex,
          }}
        />
      </Col>

      <ColButtonWrapperStyled span={24}>
        <Space>
          <Button onClick={handleClickAtExitButton}>{exitButton}</Button>
          <Button type={'primary'} onClick={handleClickAtSaveButton}>
            {saveButton}
          </Button>
        </Space>
      </ColButtonWrapperStyled>

      <SpaceStyled span={24} />
    </Row>
  );
};

const ColButtonWrapperStyled = styled(Col)`
  text-align: right;
`;

const SpaceStyled = styled(Col)`
  height: 50px;
`;

export default Content;
